import { useUsersContext } from 'context/usersContext';
import MyDropzone from 'pages/Chat/components/MyDropzone';
import React, { useEffect, useRef, useState } from 'react'
import filesubnmitted from "pages/Chat/folder-access.ico"
import Icon from 'components/Icon';
import { useHistory } from 'react-router-dom';
import googleDrive from "../../assets/images/googleDrive.png"
import dropBox from "../../assets/images/dropBox.png"
import oneDrive from "../../assets/images/OneDrive.png"
import env from 'config';
import axios from 'axios';
import DropboxChooser from 'pages/Chat/ThirdPartyServices/DropboxChooser';
import GoogleDrivePickers from 'pages/Chat/ThirdPartyServices/GoogleDrivePickers';


function Index({ classname, chatId, onFileSelect, setFileType, setSelectedFiles }) {
  const BASE_URL = env.API_URL + "/v1"
  const { agencyInfo, contextuploadFilefrominput, SSO, enableChat, setUserAsUnread, refresh, justToSaveMsg, showToast, ChatSubmit } = useUsersContext();
  const history = useHistory();
  const [alertModel, setAlertModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [modelContent, setModelContent] = useState('');
  const [loader, setLoader] = useState(false);
  const [toggleOption, setToggleOption] = useState(false)
  const [selectFileModel, setSelectFileModel] = useState(false)
  const [mediaLibraryModel, setMediaLibraryModel] = useState(false)
  const [tempSelectedFiles, setTempSelectedFiles] = useState([]);
  const [files, setFiles] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [convertedFiles, setConvertedFiles] = useState([]);
  const [mediaLibraryData, setMediaLibraryData] = useState([]);
  const [tempSelectedFilesMedia, setTempSelectedFilesMedia] = useState([]);
  const [searchMediaTerm, setSearchMediaTerm] = useState("");

  const filteredMediaLibraryData = mediaLibraryData?.filter((item) =>
    item.name.toLowerCase().includes(searchMediaTerm.toLowerCase())
  );

  const handleCloseModal = () => {
    setMediaLibraryModel(false); // Close the modal
    setSearchMediaTerm(''); // Clear the search term
  };

  const getMediaFiles = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/assistant/files/media-library?altId=${SSO?.id}&altType=location&objectId=${SSO?.id}&whichObject=account`
      );
      if (response) {
        const files = response.data.data.files;

        // Set the media library data
        setMediaLibraryData(files);

        // Process and convert files
        const downloadedFiles = await downloadFiles(
          files?.map((file) => ({
            url: file.url,
            name: file.name,
            mimeType: file.contentType, // Assuming contentType corresponds to mimeType
          }))
        );


        // Store converted files in state
        setConvertedFiles(downloadedFiles);
      }
    } catch (error) {
      console.error("Error fetching media files:", error);
    }
  };

  // Helper function to download and convert files
  const downloadFiles = async (files) => {
    return Promise?.all(
      files?.map(async (file) => {
        try {
          const response = await fetch(file.url);
          const blob = await response.blob();
          return new File([blob], file.name, { type: file.mimeType });
        } catch (error) {
          console.error("Error downloading file:", error);
          return null;
        }
      })
    ).then((results) => results.filter(Boolean));
  };



  useEffect(() => {
    getMediaFiles()
  }, [])


  const handleFileSelectMedia = (file) => {
    setTempSelectedFilesMedia((prev) => {
      const isAlreadySelected = prev.some((selectedFile) => selectedFile.id === file.id);
      if (isAlreadySelected) {
        // Remove the file if already selected
        return prev.filter((selectedFile) => selectedFile.id !== file.id);
      }
      // Add the file if not selected
      return [...prev, file];
    });
  };



  const handleDoneClickMedia = async () => {
    const assistant = [{ value: "asst_Cwkwlf4oUGjIWmuOrUiBqgx9" }];

    onFileSelect(convertedFiles)
    setMediaLibraryModel(false)
    setTempSelectedFilesMedia([])
  }



  const handleAction = () => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), 3000); // Disable for 3 seconds
  };

  const filteredData = Array.isArray(files)
    ? files.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    : [];

  const filteredFiles = filteredData.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFileSelect = (file) => {
    setTempSelectedFiles((prevSelectedFiles) => {
      const isAlreadySelected = prevSelectedFiles.some(selectedFile => selectedFile.id === file.id);
      if (isAlreadySelected) {
        return prevSelectedFiles.filter(selectedFile => selectedFile.id !== file.id);
      } else {
        return [...prevSelectedFiles, file];
      }
    });
  };


  const handleDoneClick = () => {
    setFileType('KB')
    onFileSelect(tempSelectedFiles);
    setSelectFileModel(false);
    setToggleOption(false);
    setTempSelectedFiles([]);
  };

  const gettingfilefromdropzone = async (files) => {
    setLoader(true);
    const response = await contextuploadFilefrominput(files, chatId, setSpinnerLoading);
    if (response?.data?.success === true) {
      // Case 1: `response.data.data` is available
      if (response?.data?.data) {
        onFileSelect(response.data.data);

        if (response?.data?.filetype === "audiofile" && (!chatId || chatId === undefined)) {
          const payload = {
            locationId: SSO?.id,
            username: SSO?.chat_username,
          };

          enableChat();

          const temp = await ChatSubmit(payload, true);

          if (!temp?.response) {
            refresh(temp);

            const chatUrl = `/chat/${temp.id}`;
            setUserAsUnread(temp.id);
            justToSaveMsg(response?.data?.data?.data, temp.id);
            history.push(chatUrl);
          }
          justToSaveMsg(response?.transcript, chatId);
        }

        // Show success modal for file upload
        setModelContent("File uploaded successfully!");
        setAlertModel(false);
        setSuccessModel(true);
        setSelectFileModel(false);
        setToggleOption(false);

        // If crash files also exist, show a toast for those
        if (response?.data?.crashfiles && response?.data?.crashfiles.length > 0) {
          showToast(
            `These files could not be uploaded due to a token expiration error: ${response.data.crashfiles.join(', ')}`
          );
        }
      }
      // Case 2: No `response.data.data`, only `response.data.crashfiles`
      else if (response?.data?.crashfiles && response?.data?.crashfiles.length > 0) {
        showToast(
          `These files could not be uploaded due to a token expiration error: ${response.data.crashfiles.join(', ')}`
        );
        setAlertModel(false);
        setLoader(false);
      }
    }
    // Case 3: No `data.data` and no `crashfiles`
    else {
      showToast(response?.message ? response?.message : `Something went wrong`, 'err');
      setLoader(false);
    }
  }

  const getUploadFiles = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/chat/assistants/files?profile_id=${SSO?.id}&user_id=${SSO?.userUUID}`)
      if (response) {
        setFiles(response?.data?.data?.data);
        setSpinnerLoading(false)
      }
    } catch (error) {
      return error;
    }

  }


  useEffect(() => {
    getUploadFiles()
  }, [selectFileModel === true])

  const threeDotRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (threeDotRef.current && !threeDotRef.current.contains(event.target)) {
        setToggleOption(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [threeDotRef]);

  const filesFromThirdParty = async (files) => {
    var response = await contextuploadFilefrominput(files, chatId, setSpinnerLoading);
    if (response?.data?.success === true) {
      onFileSelect(response?.data?.data);
    }
  };

  return (
    <div ref={threeDotRef}>
      {/* alerts */}
      {alertModel && <>
        <div className="modal-zz" onClick={() => setAlertModel(false)}>
          <div className="modal-content-upload" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 5,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={() => setAlertModel(false)}
            >
              X
            </p>

            <MyDropzone gettingfilefromdropzone={gettingfilefromdropzone} setLoader={setLoader} loader={loader} />
          </div>
        </div>
      </>}

      {selectFileModel && (
        <div className="select_file_modal" onClick={() => setSelectFileModel(false)}>
          <div className="select_file_modal_content" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 10,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={() => setSelectFileModel(false)}
            >
              X
            </p>

            <div style={{ marginBottom: "34px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <b className='text_black'>Select a file</b>

              <input
                type="search"
                placeholder="Search"
                style={{ outline: "1px solid black", borderRadius: "5px", width: "50%" }}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>


            <div style={{ maxHeight: "350px", overflow: "auto" }}>
              <div className='v3_modal_table_heading'>
                <p>File Name</p>
                <p style={{ textAlign: "center" }}>Date created</p>
                <p></p>
              </div>

              <div>
                {filteredFiles.length > 0 ? (
                  filteredFiles.map((item, index) => (
                    <div className="v3_modal_table_body" key={index}>
                      <p>
                        {item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name}
                      </p>
                      <p style={{ textAlign: "center" }}>
                        {new Date(item.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                      <div className="v3_modal_table_body_input">
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          onChange={() => handleFileSelect(item)}
                          checked={tempSelectedFiles.some(selectedFile => selectedFile.id === item.id)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ alignItems: "center", display: "flex" }}>No files found</p>
                )}
              </div>
            </div>

            <button className='v3_modal_table_button' onClick={handleDoneClick}>
              Done
            </button>
          </div>
        </div>
      )}

      {mediaLibraryModel && (
        <div className="select_file_modal" onClick={handleCloseModal}>
          <div className="select_file_modal_content" onClick={(e) => e.stopPropagation()}>
            <p
              style={{
                position: "absolute",
                right: 10,
                width: "12px",
                top: "10px",
                height: "12px",
                cursor: "pointer",
              }}
              onClick={handleCloseModal}
            >
              X
            </p>

            <div style={{ marginBottom: "34px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <b className='text_black'>Select a file</b>

              <input
                type="search"
                placeholder="Search"
                style={{ outline: "1px solid black", borderRadius: "5px", width: "50%" }}
                value={searchMediaTerm}
                onChange={(e) => setSearchMediaTerm(e.target.value)}
              />
            </div>


            <div style={{ maxHeight: "350px", overflow: "auto" }}>
              <div className='v3_modal_table_heading'>
                <p>File Name</p>
                <p style={{ textAlign: "center" }}>Date created</p>
                <p></p>
              </div>

              <div>
                {filteredMediaLibraryData?.length > 0 ? (
                  filteredMediaLibraryData?.map((item, index) => (
                    <div className="v3_modal_table_body" key={index}>
                      <p>
                        {item.name.length > 15 ? item.name.slice(0, 15) + "..." : item.name}
                      </p>
                      <p style={{ textAlign: "center" }}>
                        {new Date(item.created_at).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </p>
                      <div className="v3_modal_table_body_input">
                        <input
                          type="checkbox"
                          style={{ cursor: "pointer" }}
                          onChange={() => handleFileSelectMedia(item)}
                          checked={tempSelectedFilesMedia.some(selectedFile => selectedFile.id === item.id)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ alignItems: "center", display: "flex", textAlign: "center" }}>No files found</p>
                )}
              </div>
            </div>

            <button className='v3_modal_table_button' onClick={handleDoneClickMedia}>
              Done
            </button>
          </div>
        </div>
      )}


      {/* {
        successModel && <>
          <div className="modal-zz" onClick={() => setSuccessModel(false)}>
            <div className="modal-content-zz" onClick={(e) => e.stopPropagation()}>
              <p
                style={{
                  position: "absolute",
                  right: 5,
                  width: "12px",
                  top: "10px",
                  height: "12px",
                  cursor: "pointer",
                }}
                onClick={() => setSuccessModel(false)}
              >
                X
              </p>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src={filesubnmitted} width={"200px"} height={"200px"} />

                <h1 className="text-center">Files are Submitted!</h1>

              </div>
            </div>
          </div>
        </>
      } */}

      <button aria-label="Send message" className={classname}>
        <div className="v3_hover-text">
          <div onClick={() => { setToggleOption(!toggleOption); handleAction() }}>
            {spinnerLoading ? <div className="file_spinner"></div> : <Icon id="vectorfilev3" className="v3_chat__input-icon" />}
          </div>

          {/* {agencyInfo?.Customization?.uploadicone_text && (
            <span className="v3_tooltip-text" id="uploadtext">
              {agencyInfo?.Customization?.uploadicone_text}
            </span>
          )} */}
          {/* Dropdown Menu */}

          {
            toggleOption && (<div className="v3_dropdown" >
              {
                <div>
                  {/* {SSO?.isGHLUser && (<span
                    onClick={() => {
                      setMediaLibraryModel(!mediaLibraryModel)
                    }}
                  >

                    <div style={{ paddingRight: "7px" }}>
                      <Icon id="upload" />
                    </div>
                    Media Library
                  </span>)} */}

                  <span >
                    <img src={googleDrive} alt='googleDrive' style={{ marginRight: "7px" }} />
                    <GoogleDrivePickers disabled={disabled} files_send_to_parent={filesFromThirdParty} setSpinnerLoading={setSpinnerLoading} />
                  </span>

                  <span>
                    <img src={dropBox} alt='googleDrive' style={{ marginRight: "7px" }} />
                    <DropboxChooser disabled={disabled} files_send_to_parent={filesFromThirdParty} setSpinnerLoading={setSpinnerLoading} />
                  </span>
                </div>
              }

              {/* <span>
                <img src={oneDrive} alt='googleDrive' style={{ marginRight: "7px" }} />
                <OneDrivePicker files_send_to_parent={filesFromThirdParty}  />
              </span> */}

              <span
                onClick={() => {
                  setAlertModel(true);
                  setToggleOption(false);
                }}
                style={{ borderBottom: "1px solid #E5E5E5", borderTop: "1px solid #E5E5E5" }}>

                <div style={{ paddingRight: "7px" }}>
                  <Icon id="upload" />
                </div>
                Upload From Computer
              </span>

              <span
                onClick={() => {
                  setSelectFileModel(!selectFileModel);
                  setToggleOption(false);
                }}
              >
                <div style={{ marginRight: "7px" }}>
                  <Icon id="selectFile" />
                </div>
                Select a File
              </span>

            </div>
            )
          }
        </div>
      </button>

    </div>
  )
}

export default Index
