import React, { useState, useEffect, useRef, useContext } from "react";
import Icon from "components/Icon";
import Uploadfile from "../../../components/uploadFiles"
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import { useUsersContext } from "context/usersContext";

const ChatInput = ({
  showAttach,
  setShowAttach,
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  setFooterHeight,
  footerHeight,
  setGoBottomBtn,
  uploadFilefrominput,
  agencyInfo,
  chatId,
  onFileSelect,
  selectedFiles,
  updatestateodfiles,
  setFileType,
  setSelectedFiles,
  asistantSelected,
  setAskAssistant,
  askAssistant
}) => {
  const textareaRef = useRef(null);
  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      if (selectedFiles.length >= 10) {
        const container = document.getElementById("errorofmaxfile");
        let div = document.createElement("div");
        div.style.display = "flex";
        div.style.justifyContent = "center";
        div.className = "file_upload_input";
        div.innerHTML = `
        <span style="position: absolute; top: 5px; right: 10px; cursor: pointer;" onclick="this.parentElement.remove()">&#10005;</span>
        <p style="padding: 15px; margin: 0px; color: red; text-align: center;">
            You can only upload 10 files at a time
        </p>
    `;
        container.appendChild(div);

        // Automatically remove the div after 5 seconds
        setTimeout(() => {
          div.remove();
        }, 5000);

        return true;
      }
      e.preventDefault();

      e.target.style.height = "50px";
      updatestateodfiles([]);
      if (newMessage.trim()) {
        if (placeholdervalue === "What can I help you with next?") {
          submitNewMessage(newMessage, 'savetodb', "notimage");
        } else {
          submitNewMessage(newMessage, 'savetodb', "image");
          setplaceholdervalue(`What can I help you with next?`)
        }
      }
    };
  }
  
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const { promptsData, getPrompts } = useContext(PromptsContext);
  const [placeholdervalue, setplaceholdervalue] = useState(`What can ${'I'} help you with next?`);

  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }

  useEffect(() => {
    setplaceholdervalue(`What can I help you with next?`)
  }, [asistantSelected])

  return (
    <div className="chat__input-wrapper" >
      {/* ... other components ... */}

      <AddPromptModal
        isModalOpen={isOpenAddPromptModal}
        categories={promptsData?.prompt_category?.filter(obj => obj.type !== 'super-admin')}
        subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type !== 'super-admin')}
        justCloseIt={justCloseIt}
        getUpdatePromptsData={getPrompts}

      />



      {/* <div className="position-relative text-area" style={{ marginBottom: inputHeight }}></div> */}
      <textarea
        ref={textareaRef}
        className="v3chat__input"
        placeholder={placeholdervalue}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={detectEnterPress}
        onInput={(e) => {
          e.target.style.height = 'auto'; // Reset height
          e.target.style.height = `${e.target.scrollHeight}px`; // Set height to scrollHeight
        }}
        style={{
          overflowY: 'auto',
          overflowX: 'hidden', // Prevent horizontal scrolling
          resize: 'none', // Prevent manual resizing
          width: '100%',
          maxHeight: "30px",
          paddingLeft: '90px',
          borderRadius: '0px 0px 22px 22px',
        }}
        rows={1} // Start with a single row
      />


      <button aria-label="Send message" onClick={() => submitNewMessage(newMessage, 'savetodb', placeholdervalue === "What can your AI help you with next?" ? "notimage" : "image")} className="sendChatBtn">
        <Icon id="sendChat" className="chat__input-icon" />
      </button>

      <button style={{ position: "absolute", left: "18px", outline: "none" }} onClick={() => setAskAssistant(!askAssistant)}>
        <Icon id="aichatIconInput" />
      </button>

      <Uploadfile classname="filecogChatBtn" setSelectedFiles={setSelectedFiles} chatId={chatId} onFileSelect={onFileSelect} setFileType={setFileType} />
      {/* 
      <button aria-label="Send message" className="chat__input-icon-2" onClick={() => setplaceholdervalue("Type your imagination and watch it come to life!")}>
        <Icon id="uploadimgIcon" />
      </button> */}

    </div>
  );
};

export default ChatInput;
